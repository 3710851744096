import { Config } from './types';

export const baseApiUri = process.env.REACT_APP_API_URL || 'https://inwf4fbyvm.eu-west-1.awsapprunner.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Parker',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/8157d528-4c03-4529-532d-589dee168c00/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#000',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#000',
};
